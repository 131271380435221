// extracted by mini-css-extract-plugin
export var active = "pagination-module--active--e4b91";
export var container = "pagination-module--container--457de";
export var dots = "pagination-module--dots--a11cb";
export var errorBlink = "pagination-module--error-blink--e2ccd";
export var grid = "pagination-module--grid--1f952";
export var linksBox = "pagination-module--links-box--c9956";
export var next = "pagination-module--next--7463d";
export var pageLink = "pagination-module--page-link--0976d";
export var prev = "pagination-module--prev--cef55";
export var visible = "pagination-module--visible--fb523";